import {Tooltip} from '@customink/pigment-react';
import React from 'react';

function QuoteTooltip({
  backColor,
  children,
  frontColor,
  onTooltipOpen,
  quantity,
  sx = {}
}) {
  const pluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

  const inkColors = (front, back) => {
    if (Number.isNaN(front) || Number.isNaN(back)) {
      return '1 ink color';
    }
    return `${pluralize(Math.max(front, back), 'ink color')}`;
  };

  const colorInfo = (front, back) =>
    `${inkColors(front, back)}, ${back > 0 ? 2 : 1}-sided design`;

  const tooltipHeaderStyles = {
    fontWeight: 'bold',
    padding: '10px 0'
  };

  const tooltipFontSize = {
    fontSize: '14px'
  };

  const renderTooltipContent = () => {
    return (
      <div className="pc-Styles-defaultQuote-tooltip-wrapper pc-Tooltip-wrapper">
        <div className="pc-Styles-defaultQuote-tooltip-closer pc-Tooltip-closer">
          Close
        </div>
        <div
          className="pc-Styles-defaultQuote-tooltip-header"
          style={tooltipHeaderStyles}>
          All inclusive price for {pluralize(quantity, 'item')}:
        </div>
        <ul style={tooltipFontSize}>
          <li className="pc-Styles-defaultQuote-tooltip-colorInfo">
            {colorInfo(parseInt(frontColor, 10), parseInt(backColor, 10))}
          </li>
          <li>
            <strong>Free</strong> delivery
          </li>
          <li>
            <strong>Free</strong> artwork review
          </li>
          <li>
            <strong>Free</strong> artwork setup
          </li>
        </ul>
        <div style={tooltipHeaderStyles}>Buy more, save more.</div>
        <p style={tooltipFontSize}>
          Watch your price decrease with each additional item in your order.
        </p>
      </div>
    );
  };

  return (
    <Tooltip
      sx={{height: '2rem', width: '2rem', ...sx}}
      arrow
      title={renderTooltipContent()}
      placement="top-end"
      onClick={(event) => {
        event.preventDefault();
        return onTooltipOpen();
      }}
      onMouseEnter={() => onTooltipOpen()}
      componentsProps={{
        tooltip: {
          sx: {
            '& .MuiTooltip-arrow': {
              color: 'white'
            },
            background: 'white',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.2)',
            color: 'black',
            fontFamily: 'SharpSans',
            fontSize: '14px',
            minWidth: {
              lg: '245px'
            },
            width: {
              lg: '21vw',
              xl: '17vw'
            }
          }
        }
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [50, 0]
            }
          }
        ]
      }}>
      {children}
    </Tooltip>
  );
}

export default QuoteTooltip;
